import { ActionProps } from '~/components/Actions/props';
import IconLink from '~/icons/Link';
import IconNote from '~/icons/Note';
import IconQuestionCircle from '~/icons/QuestionCircle';
import IconThoughtBubble from '~/icons/ThoughtBubble';

const useNotes = ({
  espaceId,
  pathname,
}: {
  espaceId: string;
  pathname: string;
}): (ActionProps | ActionProps[])[] | undefined => {
  const isActive = (to: string, strict?: boolean): string | undefined => {
    if (strict) {
      return `/espaces/${espaceId}/notes${to}` === pathname
        ? 'is-active'
        : undefined;
    }

    return pathname.slice(0, `/espaces/${espaceId}/notes${to}`.length) ===
      `/espaces/${espaceId}/notes${to}`
      ? 'is-active'
      : undefined;
  };

  return [
    {
      className: isActive(``, true),
      icon: IconNote,
      label: 'Notes',
      to: `/espaces/${espaceId}/notes`,
    },
    {
      className: isActive(`/thoughts`),
      icon: IconThoughtBubble,
      label: 'Pensées',
      to: `/espaces/${espaceId}/notes/thoughts`,
    },
    {
      className: isActive(`/questions`),
      icon: IconQuestionCircle,
      label: 'Questions',
      to: `/espaces/${espaceId}/notes/questions`,
    },
    {
      className: isActive(`/extracts`),
      icon: IconLink,
      label: 'URLs Extraites',
      to: `/espaces/${espaceId}/notes/extracts`,
    },
  ];
};

export default useNotes;
